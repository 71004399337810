/* eslint-disable no-lonely-if */
import {
  Collapse, Drawer, List, ListItemButton, ListItemText, Toolbar,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ThemeFilterType, selectMenuThemeTypes, selectFilter, setFilter, setFilteredThemes, resetCurrent,
} from 'services/themesSlice';
import { selectBusinessFunctionalities, selectMarketCategories } from 'services/adminSlice';
import { useNavigate, useParams } from 'react-router';
import { Urls } from 'navigation/Urls';
import { useQuery } from 'pages/themes/details/ThemeDetails';
import scrollWindowToTop from 'shared/scrollWindowToTop';
import { getProductTypesMenu, selectProductTypesMenu } from 'services/productsSlice';
import { getUserRoles } from 'services/userSlice';
import { selectCurrentCountry } from "services/topNavMenuSlice";

interface Props {
  isSalesforce: boolean
}

interface NavMenuItem {
  title: string
  productType?: string | string[],
  marketCategory?: number
  serviceType?: number
  children?: NavMenuItem[]
}

interface NavMenuSectionProps {
  title: string
  items: NavMenuItem[]
  open?: boolean
  onItemClick: (item: NavMenuItem) => void
  onToggle: () => void
}

interface NavMenuSectionItemProps {
  item: NavMenuItem
  onClick?: (it: NavMenuItem) => void
  indented?: boolean
}

const sidebarWidth = 250;

const TopLevelItem = (props: any) => (
  <ListItemButton
    sx={{
      borderBottom: '2px solid lightgray',
      '.MuiTypography-root': {
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
    }}
    {...props}
  >
    {props.children}
  </ListItemButton>
);

const CollapsibleList = (props: any) => (
  <Collapse in={props.open} timeout="auto" unmountOnExit
    sx={{
      padding: '0 !important',
      '.MuiCollapse-wrapper': {
        padding: '0 !important',
        '.MuiCollapse-wrapperInner': {
          padding: '0 !important',
        },
      },
    }}
  >
    <List component="div" sx={{ borderBottom: '2px solid lightgray', paddingY: 3 }}>
      {props.children}
    </List>
  </Collapse>
);

const NavMenuSectionItem = ({ item, onClick, indented }: NavMenuSectionItemProps) => {
  const filter = useSelector(selectFilter);

  const isSelected = useMemo(
    () => (filter.type === ThemeFilterType.PRODUCT_TYPE && item.productType === filter.value)
    // eslint-disable-next-line max-len
    || (Array.isArray(item.productType) && filter.type === ThemeFilterType.PRODUCT_TYPE && item.productType[0] === filter.value[0])
    || (filter.type === ThemeFilterType.MARKET_CATEGORY && item.marketCategory === filter.value)
    || (filter.type === ThemeFilterType.SERVICE_TYPE && item.serviceType === filter.value),
    [filter],
  );

  return (
    <>
      <ListItemButton sx={{ marginLeft: indented ? 2 : 0 }} onClick={() => onClick(item)}>
        <ListItemText primary={item.title}
          sx={{
            color: isSelected ? 'blue' : '',
            '.MuiTypography-root': { fontWeight: isSelected ? 'bold' : 'normal' },
          }}
        />
      </ListItemButton>
      {(item.children?.length > 1) && (
        <List component="div">
          {item.children.map(child => (
            <NavMenuSectionItem indented onClick={() => onClick(child)} key={child.title}
              item={child}
            />
          ))}
        </List>
      )}
    </>
  );
};

const NavMenuSection = ({
  title, items, open, onItemClick, onToggle,
}: NavMenuSectionProps) => {
  const handleClick = () => onToggle();

  return (
    <>
      <TopLevelItem onClick={handleClick}>
        <ListItemText primary={title} />
      </TopLevelItem>
      <CollapsibleList open={open}>
        {items.map(item => <NavMenuSectionItem onClick={onItemClick} key={item.title} item={item} />)}
      </CollapsibleList>
    </>
  );
};

export default function CoLeftNav({ isSalesforce }: Readonly<Props>) {
  const { opportunityId, solutionId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const menuThemes = useSelector(selectMenuThemeTypes);
  const marketCategories = useSelector(selectMarketCategories);
  const businessFunctionalities = useSelector(selectBusinessFunctionalities);
  const currentFilter = useSelector(selectFilter);
  const productTypes = useSelector(selectProductTypesMenu);
  const [menuItems, setMenuItems] = useState([]);
  const [menuOpen, setMenuOpen] = useState([true, false, false]);
  const partnerAccountId = useMemo(() => query.get('partnerAccount'), [query]);
  const isPartnerRole = getUserRoles().includes('ROLE_PARTNER_CEF');
  const currentCountry = useSelector(selectCurrentCountry);

  const partnerProducts = ['RL Branded Content',
    'Campaign Landing Pages', 'Chat Platinum', 'Chat', 'Spanish Chat Addon', 'Chat Hybrid Addon',
    'Custom Solutions - Linkedin', 'Custom Solutions - Pandora', 'Custom Solutions - Snapchat',
    'Custom Solutions - Spotify', 'Custom Solutions - Tiktok', 'Custom Solutions - Twitter',
    'Custom Tracking', 'Custom Reporting', 'Display Creative Service', 'Display Creative Service - Custom',
    'Google Tag Manager', 'Premium Listings', 'Premium Listings 10 Plus',
    'Premium Listings 10 Plus Physician Directories', 'Search Engine Marketing', 'SEO', 'Shopping Ads',
    'Social Ads - Ad Engagement Add-On', 'Social Ads - Custom', 'Social Ads Lead Ads',
    'Social Ads Lead Ads with Detailed Targeting', 'Social Ads Retargeting',
    'Social Ads Website Clicks', 'Social Ads Website Clicks with Detailed Targeting',
    'Social Ads with Smart Optimization Technology', 'SMM', 'Targeted Display', 'Targeted Email',
    'Video Creative Service Package', 'Custom Website', 'Website - Managed Services', 'GoldSite',
    'Websites Content Migration', 'Website Add-On - Content Pages', 'XMO', 'YouTube', 'Customer Center',
    'Landing Pages Managed Services','Customer Center By Dash'];

  const ausProducts = (products) => {
    return Object.keys(products).filter(key => key.startsWith("AUS"));
  };

  const partnerProductsSEOSMM = ['seo-standard', 'seo-custom', 'seo-topic-location',
    'smm-custom', 'smm-infographics', 'smm-instagram', 'smm-pinterest', 'dmsnext-cc', 'dmsnext-cc-by-dash'];

  useEffect(() => {
    dispatch(getProductTypesMenu({}));
  }, []);

  const buildMenu = () => {
    const ausProductNames = ausProducts(productTypes);
    const productTypesFiltered = Object.keys(productTypes);
    const newproductTypes = [];
    const isProd = (window.location.href
      && (window.location.href.indexOf('orders.localiq.com') >= 0 || process.env.NODE_ENV === 'production'));
    productTypesFiltered.forEach(element => {
      // check if enabled, by stripe flag, temporary solution
      const typesFiltered = productTypes[element].filter(type => ((!isProd) || (type.stripe && type.stripe === true)));
      if (typesFiltered && typesFiltered.length > 0) {
        newproductTypes.push({
          title: element,
          productType: typesFiltered.map(type => type.code.replaceAll(' ', '+')),
          children: ['SEO', 'SMM', 'Customer Center'].includes(element)
            ? []
            : typesFiltered.map(type => ({
              title: type.name,
              productType: type.code.replaceAll(' ', '+'),
            })),
        });
      }
    });

    if (currentCountry?.name === "AUS" || currentCountry?.name === "NZL") {
      const ausProductArr = [];
      if (newproductTypes.length > 0) {
        newproductTypes.forEach((prod: any) => {
          if (prod.children.length > 0) {
            prod.children.forEach((item: any) => {
              if (ausProductNames.includes(item.title)) {
                ausProductArr.push(item);
              }
            });
          }
        });
      }
      setMenuItems(ausProductArr);
    } else if (partnerAccountId || isPartnerRole) {
      const partnerProductArr = [];
      if (newproductTypes.length > 0) {
        newproductTypes.forEach((prod: any) => {
          if (prod.children.length > 0) {
            prod.children.forEach((item: any) => {
              if (partnerProducts.includes(item.title)) {
                partnerProductArr.push(item);
              }
            });
          }
          if (prod.children.length === 0 && partnerProducts.includes(prod.title)) {
            const newProdArr = [];
            prod.productType.forEach((item: any) => {
              if (partnerProductsSEOSMM.includes(item)) {
                newProdArr.push(item);
              }
            });
            partnerProductArr.push({
              title: prod.title,
              productType: newProdArr,
            });
          }
        });
      }
      setMenuItems(partnerProductArr);
    } else {
      const standardFlowProducts = () => {
        return newproductTypes.filter(product => !ausProductNames.includes(product.title));
      };
      setMenuItems(standardFlowProducts);
    }
  };

  useEffect(() => {
    if (menuThemes.length) buildMenu();
  }, [menuThemes, partnerAccountId, currentCountry]);

  useEffect(() => {
    if (menuItems.length > 0) {
      navigate(`/${Urls.Themes}`)
    }
  }, [ currentCountry]);
  useEffect(() => {
    const product = query.get('product');
    const marketCategory = query.get('marketCategory');
    const serviceType = query.get('serviceType');
    if (product) setMenuOpen([true, false, false]);
    if (marketCategory) setMenuOpen([false, true, false]);
    if (serviceType) setMenuOpen([false, false, true]);
  }, [query]);

  const filterThemes = (filterType, filterValue, propToFilter) => {
    dispatch(setFilter({ type: filterType, value: filterValue }));
    const filteredThemes = menuThemes.filter(theme => (
      propToFilter === 'product'
        ? theme[propToFilter].replaceAll(' ', '+')
        : theme[propToFilter].id) === filterValue);
    dispatch(setFilteredThemes(filteredThemes));
  };

  useEffect(() => {
    if (partnerAccountId && menuItems.length > 0 && currentFilter.value === '') {
      filterThemes(ThemeFilterType.PRODUCT_TYPE, menuItems[0].productType, 'product');
    }
  }, [partnerAccountId, menuItems, currentFilter]);

  const handleProductTypeClick = (item: NavMenuItem) => {
    if (Array.isArray(item.productType)) {
      dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: item.productType }));
      const allChildren = [];
      item.productType.forEach(type => {
        const filtered = menuThemes.filter(theme => theme.product.replaceAll(' ', '+') === type);
        if (filtered.length > 0) filtered.forEach(item => allChildren.push(item));
      });
      dispatch(setFilteredThemes(allChildren));
    } else filterThemes(ThemeFilterType.PRODUCT_TYPE, item.productType, 'product');

    if (partnerAccountId) {
      navigate(`/${Urls.Themes}?product=${item.productType}&partnerAccount=${partnerAccountId}`);
    } else {
      navigate(`/${Urls.Themes}?product=${item.productType}`);
    }
    scrollWindowToTop();
  };

  const handleMarketCategoryClick = async (item: NavMenuItem) => {
    if (currentFilter.type === ThemeFilterType.MARKET_CATEGORY
      && currentFilter.value === item.marketCategory && isSalesforce) {
      dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: '' }));
      const filtered = menuThemes.filter(theme => (theme.featured && theme.active));
      dispatch(setFilteredThemes(filtered));
      dispatch(resetCurrent({}));
      if (solutionId) {
        navigate(`/${Urls.Cef}/${opportunityId}/${solutionId}`);
      } else navigate(`/${Urls.Cef}/${opportunityId}`);

      return;
    }
    filterThemes(ThemeFilterType.MARKET_CATEGORY, item.marketCategory, 'marketCategory');
    if (!isSalesforce) {
      navigate(`/${Urls.Themes}?marketCategory=${item.marketCategory}`);
    } else if (solutionId) {
      navigate(`/${Urls.Cef}/${opportunityId}/${solutionId}?marketCategory=${item.marketCategory}`);
    } else navigate(`/${Urls.Cef}/${opportunityId}?marketCategory=${item.marketCategory}`);
    scrollWindowToTop();
  };

  const handleServiceTypeClick = async (item: NavMenuItem) => {
    if (currentFilter.type === ThemeFilterType.SERVICE_TYPE
      && currentFilter.value === item.serviceType && isSalesforce) {
      dispatch(setFilter({ type: ThemeFilterType.PRODUCT_TYPE, value: '' }));
      const filtered = menuThemes.filter(theme => (theme.featured && theme.active));
      dispatch(setFilteredThemes(filtered));
      dispatch(resetCurrent({}));
      if (solutionId) {
        navigate(`/${Urls.Cef}/${opportunityId}/${solutionId}`);
      } else navigate(`/${Urls.Cef}/${opportunityId}`);
      return;
    }
    filterThemes(ThemeFilterType.SERVICE_TYPE, item.serviceType, 'businessFunctionality');
    if (!isSalesforce) {
      navigate(`/${Urls.Themes}?serviceType=${item.serviceType}`);
    } else if (solutionId) {
      navigate(`/${Urls.Cef}/${opportunityId}/${solutionId}?serviceType=${item.serviceType}`);
    } else navigate(`/${Urls.Cef}/${opportunityId}?serviceType=${item.serviceType}`);
    scrollWindowToTop();
  };

  const handleMenuToggle = (index) => {
    if (menuOpen[index]) {
      const menuCopy = [...menuOpen];
      menuCopy.splice(index, 1, false);
      setMenuOpen(menuCopy);
    } else {
      const newMenuState = [false, false, false];
      newMenuState[index] = true;
      setMenuOpen(newMenuState);
    }
  };

  return (
    <Drawer
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        marginLeft: `${!isSalesforce ? '50px' : '0'}`,
        '& .MuiDrawer-paper': {
          position: 'initial',
          height: 'auto',
          overflow: 'visible',
          backgroundColor: 'transparent',
          borderRight: 0,
          width: sidebarWidth,
          boxSizing: 'border-box',
          paddingLeft: `${!isSalesforce ? '20px' : '0'}`,
          marginTop: `${!isSalesforce ? '100px' : '0'}`,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      {!isSalesforce && <Toolbar />}
      <List component="nav">
        {!isSalesforce && (
          <NavMenuSection
            open={menuOpen[0]}
            items={menuItems}
            title="Product Types"
            onItemClick={handleProductTypeClick}
            onToggle={() => handleMenuToggle(0)}
          />
        )}
        {!partnerAccountId && (
          <>
            <NavMenuSection
              open={menuOpen[1]}
              items={marketCategories.map(cat => ({ title: cat.name, marketCategory: cat.id }))}
              title="Market Categories"
              onItemClick={handleMarketCategoryClick}
              onToggle={() => handleMenuToggle(1)}
            />
            <NavMenuSection
              open={menuOpen[2]}
              items={businessFunctionalities.map(f => ({ title: f.name, serviceType: f.id }))}
              title="Service Types"
              onItemClick={handleServiceTypeClick}
              onToggle={() => handleMenuToggle(2)}
            />
          </>
        )}
      </List>
    </Drawer>
  );
}
